<template>
  <div>
    <b-sidebar
      id="sidebar-add-new-event"
      sidebar-class="sidebar-lg"
      :visible="handlerSidebarShow"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => sideBarChange(val)"
    >
      <template>
        <!-- Header -->
        <div
          class="
            d-flex
            justify-content-between
            align-items-center
            content-sidebar-header
            px-2
            py-1
          "
        >
          <h5 class="mb-0">
            {{ headerTitle }}
          </h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="$emit('update:is-event-handler-sidebar-active', false)"
            />
          </div>
        </div>
        <validation-observer ref="refFormObserver">
          <b-form
            class="p-2"
            @reset.prevent
          >
            <!-- Name -->
            <b-form-group
              :label="$t('products.name')"
              label-for="form-name"
            >
              <validation-provider
                #default="{ errors }"
                name="Name"
                :rules="{
                  required: true
                }"
              >
                <b-form-input
                  id="form-name"
                  v-model="customer.name"
                  autofocus
                  :state="errors.length > 0 ? false : null"
                  trim
                  :placeholder="$t('products.name')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Row cp and house number -->
            <b-row>
              <b-col
                cols="12"
                lg="6"
                md="6"
                sm="6"
              >
                <!-- Cp -->
                <b-form-group
                  :label="$t('customer.cp')"
                  label-for="form-city"
                >
                  <validation-provider
                    name="Cp"
                  >
                    <b-form-input
                      id="form-cp"
                      v-model="customer.cp"
                      autofocus
                      trim
                      :placeholder="$t('customer.cp')"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                cols="12"
                lg="6"
                sm="6"
                md="6"
              >
                <!-- House Number -->
                <b-form-group
                  :label="$t('customer.house')"
                  label-for="form-city"
                >
                  <validation-provider
                    name="House"
                  >
                    <b-form-input
                      id="form-house"
                      v-model="customer.house_number"
                      autofocus
                      trim
                      :placeholder="$t('customer.house')"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- Address -->
            <b-form-group
              :label="$t('customer.address')"
              label-for="form-address"
            >
              <validation-provider
                name="Address"
              >
                <b-form-input
                  id="form-address"
                  v-model="customer.address"
                  autofocus
                  trim
                  :placeholder="$t('customer.address')"
                />
              </validation-provider>
            </b-form-group>

            <b-row
              class="justify-content-space-around"
              align-v="center"
            >
              <b-col>
                <!-- City -->
                <b-form-group
                  :label="$t('customer.city')"
                  label-for="form-city"
                >
                  <validation-provider
                    name="City"
                  >
                    <b-form-input
                      id="form-city"
                      v-model="customer.city"
                      autofocus
                      trim
                      :placeholder="$t('customer.city')"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- Phone -->
            <b-form-group
              :label="$t('user.phone')"
              label-for="form-name"
            >
              <validation-provider
                #default="{ errors }"
                name="Phone"
                :rules="{
                  required: true
                }"
              >
                <b-form-input
                  id="form-phone"
                  v-model="customer.phone"
                  autofocus
                  :state="errors.length > 0 ? false : null"
                  trim
                  :placeholder="$t('user.phone')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Email -->
            <b-form-group
              :label="$t('user.email')"
              label-for="form-name"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                :rules="{
                  required: true,
                  email: true
                }"
              >
                <b-form-input
                  id="form-email"
                  v-model="customer.email"
                  autofocus
                  :state="errors.length > 0 ? false : null"
                  trim
                  :placeholder="$t('user.email')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Fiscal number -->
            <b-form-group
              :label="$t('customer.fiscal_number')"
              label-for="form-fiscal_number"
            >
              <validation-provider
                name="Nif"
              >
                <b-form-input
                  id="form-fiscal"
                  v-model="customer.fiscal_number"
                  autofocus
                  trim
                  :placeholder="$t('customer.fiscal_number')"
                />
              </validation-provider>
            </b-form-group>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
                @click.prevent="handleSubmit"
              >
                <feather-icon
                  icon="SaveIcon"
                  class="mr-25"
                />
                <span>{{ $t("common.save") }}</span>
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BSidebar,
  BButton,
  BRow,
  BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, numeric } from '@validations'

export default {
  components: {
    BButton,
    BForm,
    BSidebar,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'handlerSidebarShow',
    event: 'update:is-event-handler-sidebar-active',
  },
  props: {
    handlerSidebarShow: {
      type: Boolean,
      required: true,
    },
    headerTitle: {
      type: String,
      required: true,
    },
    editItem: {
      type: Object,
      default: Object,
    },
    fillAddress: {
      type: Object,
      default: () => {},
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      payload: [],
      required,
      email,
      numeric,
      customer: {
        name: '',
        house_number: '',
        phone: '',
        email: '',
        address: '',
        city: '',
        cp: '',
        fiscal_number: '',
        latitude: '',
        longitude: '',
      },
    }
  },
  watch: {
    isEditing() {
      if (this.isEditing) {
        this.customer = {
          customerId: this.editItem.id,
          name: this.editItem.name,
          phone: this.editItem.phone,
          email: this.editItem.email,
          address: this.editItem.address,
          house_number: this.editItem.house_number,
          city: this.editItem.city,
          cp: this.editItem.cp,
          fiscal_number: this.editItem.fiscal_number,
          latitude: this.editItem.latitude,
          longitude: this.editItem.longitude,
        }
      }
    },
    fillAddress() {
      if (this.fillAddress.length !== null) {
        // eslint-disable-next-line prefer-destructuring
        this.customer.address = this.fillAddress.street
        // eslint-disable-next-line prefer-destructuring
        this.customer.city = this.fillAddress.city
        this.customer.latitude = this.fillAddress.latitude
        this.customer.longitude = this.fillAddress.longitude
      }
    },
  },
  beforeUpdate() {
    this.changeAddress()
  },
  methods: {
    changeAddress() {
      this.payload = { cp: this.customer.cp, house: this.customer.house_number }
      this.payload.cp = this.payload.cp.replace(/[^a-zA-Z0-9]/g, '')
      this.payload.house = this.payload.house.replace(/[^0-9]/g, '')
      if (this.payload.cp.length === 9 || this.payload.cp.length === 6) {
        if (this.payload.house.length > 0) {
          this.$emit('onChangeAddress', this.payload)
        }
      }
    },
    requiredField(value) {
      return value !== ''
    },
    sideBarChange(val) {
      this.$emit('update:is-event-handler-sidebar-active', val)
      if (val === false) this.$emit('onClose')
    },
    handleSubmit() {
      this.$refs.refFormObserver.validate().then(success => {
        if (success) this.onSubmit()
      })
    },
    onSubmit() {
      this.$emit('onCreate', this.customer)
      this.$emit('onClose')
      // console.log('customer: ', this.customer)
      this.resetForm()
    },
    resetForm() {
      this.customer = {
        name: '',
        phone: '',
        email: '',
        address: '',
        house_number: '',
        city: '',
        cp: '',
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
