async function geoLocation(info) {
  const token = info.bearer
  let addressApi = {}
  // let filterAddress = []

  const requestHeaders = {
    // eslint-disable-next-line prefer-template
    Authorization: 'Bearer ' + token,
    Host: '<calculated when request is sent>',
  }
  const url = `https://geocode.search.hereapi.com/v1/geocode?qq=houseNumber=${info.house_number};postalCode=${info.cp}`

  const fet = await fetch(url, {
    method: 'get',
    headers: requestHeaders,
  })
  addressApi = await fet.json()
  return {
    city: addressApi.items[0]?.address?.city,
    street: addressApi.items[0]?.address?.street,
    latitude: addressApi.items[0]?.position?.lat,
    longitude: addressApi.items[0]?.position?.lng,
  }
}

export default {
  geoLocation,
}
