const request = require('request')
const OAuth = require('oauth-1.0a')
const crypto = require('crypto')

// Token request function
function generateToken() {
  // if (localStorage.getItem('geoToken')) {
  //   return localStorage.getItem('geoToken')
  // }
  const oauth = OAuth({
    consumer: {
      key: 'ojbSWX3x5NzI3VoXHYmXRw',
      secret: 'TRuFm9LfQv1NXBZDxmePaEYT05ncFzpQiwyf1YMrT6YDnX5LEKB1-qejY0weK03-SL7yTTuqDbT0F3iYnyoNwA',
    },
    signature_method: 'HMAC-SHA256',
    hash_function(baseString, key) {
      return crypto
        .createHmac('sha256', key)
        .update(baseString)
        .digest('base64')
    },
  })
  const requestData = {
    url: 'https://account.api.here.com/oauth2/token',
    method: 'POST',
    data: { grant_type: 'client_credentials' },
  }
  return new Promise((resolve, reject) => {
    request(
      {
        url: requestData.url,
        method: requestData.method,
        form: requestData.data,
        headers: oauth.toHeader(oauth.authorize(requestData)),
      },
      (error, response) => {
        if (response.statusCode === 200) {
          resolve(JSON.parse(response.body).access_token)
          localStorage.setItem('geoToken', JSON.parse(response.body).access_token)
        }
        if (error) reject(error)
      },
    )
  })
}

export default generateToken
