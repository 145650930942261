<template>
  <div>
    <b-sidebar
      id="sidebar-add-new-event"
      sidebar-class="sidebar-lg"
      :visible="handlerSidebarShow"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => sideBarChange(val)"
    >
      <template>
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            {{ headerTitle }}
          </h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="$emit('update:is-event-handler-sidebar-active', false)"
            />
          </div>
        </div>
        <validation-observer
          ref="refFormObserver"
        >
          <b-form
            class="p-2"
            @submit.prevent
          >
            <!-- Name -->
            <b-form-group
              v-if="$route.name !== 'drs-providers' && $route.name !== 'drs-stocks'"
              :label="`${( $t('products.name') )}*`"
              label-for="form-name"
            >
              <validation-provider
                #default="{ errors }"
                name="Name"
                rules="required"
              >
                <b-form-input
                  id="form-name"
                  v-model="editItem.name"
                  autofocus
                  :state="errors.length > 0 ? false : null"
                  trim
                  :placeholder="( $t('products.name') )"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- Stock -->

            <b-form-group
              v-if="$route.name === 'drs-stocks'"
              :label="`${( $t('products.stock') )}*`"
              label-for="form-stock"
            >
              <validation-provider
                #default="{ errors }"
                name="Stocks"
                rules="required"
              >
                <b-form-input
                  id="form-stock"
                  v-model="editItem.product_name"
                  autofocus
                  trim
                  :disabled="true"
                  :placeholder="( $t('products.stock') )"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- User Mechanics -->
            <b-form-group
              v-if="$route.name === 'drs-stock'"
              :label="`${( $t('user.user_mechanics') )}*`"
              label-for="form-mechanics"
            >
              <validation-provider
                #default="{ errors }"
                name="User Mechanics"
                rules="required"
              >
                <b-form-input
                  id="form-mechanics"
                  v-model="editItem.mechanic"
                  autofocus
                  trim
                  disabled
                  placeholder="Manuel"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Product Stocks -->

            <b-form-group
              v-if="$route.name === 'drs-stocks'"
              :label="`${( $t('products.product_stock') )}*`"
              label-for="form-product"
            >
              <validation-provider
                #default="{ errors }"
                name="Product Stock"
                rules="required"
              >
                <b-form-input
                  id="form-product"
                  v-model="editItem.units"
                  autofocus
                  trim
                  type="number"
                  :placeholder="( $t('products.product_stock') )"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <div v-if="$route.name === 'drs-providers'">
            <!-- Title -->
            <b-form-group
              :label="`${( $t('products.name') )}*`"
              label-for="form-name"
            >
              <validation-provider
                #default="{ errors }"
                name="Name"
                rules="required"
              >
                <b-form-input
                  id="form-name"
                  v-model="editItem.name"
                  autofocus
                  trim
                  :placeholder="( $t('products.name') )"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- Phone -->
            <b-form-group
              :label="( $t('user.phone') )"
              label-for="form-phone"
            >
              <validation-provider
                #default="{ errors }"
                name="Phone"
              >
                <b-form-input
                  id="form-phone"
                  v-model="editItem.phone"
                  trim
                  :placeholder="( $t('user.phone') )"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Email -->
            <b-form-group
              :label="( $t('user.email') )"
              label-for="form-email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
              >
                <b-form-input
                  id="form-email"
                  v-model="editItem.email"
                  trim
                  :placeholder="( $t('user.email') )"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- Contact Person -->
            <b-form-group
              :label="( $t('provider.contact_person') )"
              label-for="form-contact-person"
            >
              <validation-provider
                #default="{ errors }"
                name="Contact-Person"
              >
                <b-form-input
                  id="form-contact-person"
                  v-model="editItem.contact_person"
                  trim
                  :placeholder="( $t('provider.contact_person') )"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

              <!-- cp and house number -->

              <!-- Cp -->
              <b-form-group
                :label="$t('customer.cp')"
                label-for="form-city"
              >
                <validation-provider
                  name="Cp"
                >
                  <b-form-input
                    id="form-cp"
                    v-model="editItem.cp"
                    autofocus
                    trim
                    :placeholder="$t('customer.cp')"
                  />
                </validation-provider>
              </b-form-group>

              <!-- House Number -->
              <b-form-group
                :label="$t('customer.house')"
                label-for="form-city"
              >
                <validation-provider
                  name="House"
                >
                  <b-form-input
                    id="form-house"
                    v-model="editItem.house_number"
                    autofocus
                    trim
                    :placeholder="$t('customer.house')"
                    @input="changeAddress"
                  />
                </validation-provider>
              </b-form-group>

            <!-- Address -->
            <b-form-group
              :label="( $t('customer.address') )"
              label-for="form-address"
            >
              <validation-provider
                #default="{ errors }"
                name="Address"
              >
                <b-form-input
                  id="form-address"
                  v-model="editItem.address"
                  trim
                  :placeholder="( $t('customer.address') )"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

              <!-- city -->
              <b-form-group
                :label="$t('customer.city')"
                label-for="form-city"
              >
                <validation-provider
                  name="City"
                >
                  <b-form-input
                    id="form-city"
                    v-model="editItem.city"
                    autofocus
                    trim
                    :placeholder="$t('customer.city')"
                  />
                </validation-provider>
              </b-form-group>
            </div>

            <!-- City -->
            <validation-provider
              v-if="$route.name === 'drs-customers'"
              name="Cp"
              rules="required"
            >
              <b-form-group
                :label="'Cp'"
                label-for="form-city"
              >
                <b-form-input
                  id="form-city"
                  v-model="customer.cp"
                  trim
                  :placeholder="'Cp'"
                />

                <!-- <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback> -->

              </b-form-group>
            </validation-provider>

            <!-- Form Actions -->
            <div class="mt-2 float-left">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
                @click.prevent="handleSubmit"
              >
                <feather-icon
                  icon="SaveIcon"
                  class="mr-25"
                />
                <span>{{ $t("common.save") }}</span>
              </b-button>
            </div>
            <div class="mt-2 float-right">
              <b-button
                v-if="$route.name === 'drs-stocks'"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-primary"
                class=""
                type="submit"
                @click.prevent="goToProduct"
              >
                <feather-icon
                  icon="EyeIcon"
                  class="mr-25"
                />
                <span> {{ $t("stocks.view_product") }}</span>
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BSidebar,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import geoLocation from '@/models/geoLocation'
import generateToken from '@/auth/geoAuth'

export default {
  components: {
    BButton,
    BForm,
    BSidebar,
    BFormGroup,
    BFormInput,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'handlerSidebarShow',
    event: 'update:is-event-handler-sidebar-active',
  },
  props: {
    handlerSidebarShow: {
      type: Boolean,
      required: true,
    },
    headerTitle: {
      type: String,
      required: true,
    },
    fieldFlag: {
      type: String,
      default: '',
    },
    editItem: {
      type: Object,
      default: Object,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
      email,
    }
  },
  watch: {
    editItem: {
      handler() {

      },
      deep: true,
    },
  },
  methods: {
    changeAddress() {
      const payload = { cp: this.editItem.cp, house_number: this.editItem.house_number }
      payload.cp = payload.cp.replace(/[^a-zA-Z0-9]/g, '')
      payload.house_number = payload.house_number.replace(/[^0-9]/g, '')
      if (payload.cp.length === 9 || payload.cp.length === 6) {
        if (payload.house_number.length > 0) {
          this.getLocation(payload)
        }
      }
    },
    async getLocation(info) {
      const bearer = await generateToken()
      const payload = {
        cp: info.cp,
        house_number: info.house_number,
        bearer,
      }
      const fillAddress = await geoLocation.get(payload)
      this.editItem.address = fillAddress.street
      this.editItem.city = fillAddress.city
      this.editItem.latitude = fillAddress.latitude
      this.editItem.longitude = fillAddress.longitude
    },
    sideBarChange(val) {
      this.$emit('update:is-event-handler-sidebar-active', val)
      if (val === false) {
        this.$emit('onClose')
        this.resetForm()
      }
    },
    goToProduct() {
      this.$router.push({ name: 'tab-product-stock', params: { id: this.editItem.product_id } })
    },
    handleSubmit() {
      const { isEditing } = this
      this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          switch (this.$route.name) {
            case 'drs-stocks':
              this.$emit(isEditing ? 'onUpdate' : 'onCreate', this.editItem)
              break
            case 'drs-providers':
              this.$emit('onCreate', {
                id: this.editItem.id,
                name: this.editItem.name,
                phone: this.editItem.phone,
                email: this.editItem.email,
                contact_person: this.editItem.contact_person,
                address: this.editItem.address,
                city: this.editItem.city,
                cp: this.editItem.cp,
                house_number: this.editItem.house_number,
                latitude: this.editItem.latitude,
                longitude: this.editItem.longitude,
              })
              break
            case 'drs-brands':
              this.$emit('onCreate', { id: isEditing ? this.editItem.id : null, name: this.editItem.name })
              break
            case 'drs-categories':
              this.$emit('onCreate', { id: isEditing ? this.editItem.id : null, name: this.editItem.name })
              break
            default:
              this.$emit('onCreate', { name: this.editItem.name })
              break
          }

          this.resetForm()
          this.$emit('onClose')
        }
      })
    },
    resetForm() {
      this.editItem.name = ''
      this.editItem.phone = ''
      this.editItem.email = ''
      this.editItem.contact_person = ''
      this.editItem.address = ''
      this.editItem.id = null
      this.editItem.longitude = ''
      this.editItem.latitude = ''
      this.editItem.cp = ''
      this.editItem.city = ''
      this.editItem.house_number = ''
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
