import api from '@/api/index'

export default {
  getById: async payload => {
    const response = await api.provider.getById(payload)
    return response.data
  },
  get: async payload => {
    const response = await api.provider.get(payload)
    const { data } = response
    return data
  },
  create: async payload => {
    const response = await api.provider.create(payload)
    return response
  },
  update: async payload => {
    const response = await api.provider.update(payload)
    return response
  },
  delete: async payload => {
    const response = await api.provider.delete(payload)
    return response
  },
}
